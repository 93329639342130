import React from 'react'
import styled from "styled-components"
import { Link } from "gatsby"

// Styles
import '../styles/reset.css'
import { MainComponent } from '../styles/shared.styles'

import SimpleHeader from '../components/SimpleHeader'
import HowSection from '../components/HowSection'
import Footer from '../components/Footer'

export default function whoWeAre() {
  return (
    <MainComponent>
      <SimpleHeader transparent color="#B83B5E" />
      <HowSection hideSeeMore />

      <MoreInfo>
        <h2>Our process is simple</h2>
        <p>It is essential to have excellent communication and be in sync with our customers. So we can chat, make calls and use the communication tools that our customers are familiar with.</p>
        <p>As soon as we work on a project, we communicate everything within the team and the customer, using different services and tools.</p>
        <p>When we deliver, the customer gets everything such as the source code, access to the repositories, documentation, access to domains, credentials and everything related to the project.</p>
        <p>If you want to see how we deliver products and want to know what services, tools or technologies we use, go <Link to="/who-we-are">here</Link></p>
        <p>If you want to know what we can deliver, go <Link to="/what-we-do">here</Link></p>
        <p>If you wish to contact us, go <Link to="/#contact-section">here</Link></p>
      </MoreInfo>
      <Footer />
    </MainComponent>
  )
}

const MoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 23px;
  padding: 200px 50px;

  h2 {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 40px;
  }
  
  p {
    margin-bottom: 20px;
  }
`